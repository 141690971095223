option {
  border-radius: 0px;
}

/* fontWeight: "semibold",
          borderRadius: "0",
          borderColor: "gray.500",
          border: "2px",
          color: "gray.700",
          backgroundColor: "blue.300",
          boxShadow: "8px 8px 0px #383A48", */

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale(1.2);
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #ffffff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
    border-color: #ffffff;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
    border-color: #ffffff;
  }
}

.progress-wrapper {
  position: relative;
  z-index: 1;
}

.budget-progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /*background-color: #bef8cd; /* Purple: e8d9fd */
  z-index: -1;
}

.budget-overspent {
  background-color: #fed7d7;
}
